import React from 'react';
import { CircularProgress } from '@mui/material';
import _Store from '_Store';

interface Props {
    full?: boolean;
    primary?: string;
    spinning?: boolean;
    secondary?: string;
}
const Loading = (props: Props) => {

    const { _store: { _utils: { loading } } } = React.useContext(_Store);
    const { full, primary, spinning, secondary } = props;

    const Primary = primary || loading.primary;
    const Spinning = typeof spinning !== 'undefined' ? spinning : loading.spinning;
    const Secondary = secondary || loading.secondary || 'Please wait...';

    return (
        <React.Fragment>
            {Spinning && (
                <div id="Loading" className={`flex justify-center items-center rounded-xl ${full ? 'full' : ''}`}>
                    <div className="text-center">
                        {Primary && (
                            <div className="font-bold mb-1 text-xl">{Primary}</div>
                        )}
                        <div className="font-normal text-sm text-gray-400">{Secondary}</div>

                        <div className="lds-ring mt-8"><div></div><div></div><div></div><div></div></div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
}

export default Loading;

export const LoadingMin = ({ size = 25 }: { size?: number }) => {

    return (
        <div className="flexs justify-center my-4s">
            <CircularProgress size={size} />
        </div>
    );
};