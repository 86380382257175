import React from 'react';
import { DatePicker, Form, FormInstance, Select } from 'antd';
import moment from 'moment';
import { helpers } from 'helpers';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';

interface Props {
    form: FormInstance;
    name: string;
    label?: string;
    value?: string;
    disabled: boolean;
}
const DateTimePicker = ({ form, name, label, value, disabled }: Props) => {

    React.useEffect(() => {
        if (value) {
            form.setFieldsValue({
                [name]: {
                    date: moment(value),
                    time: moment(value).format('HH:mm'),
                }
            });
        } else {
            form.setFieldsValue({
                [name]: {
                    date: null,
                    time: null,
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        return current && current < dayjs().endOf('day');
    };

    return (
        <React.Fragment>
            <div className="grid grid-cols-12 gap-4">
                <div className="col-span-6">
                    <Form.Item name={[name, 'date']} label={label && `${label} date`} rules={helpers.requiredInput}>
                        <DatePicker format="MMM DD, YYYY" type="text" size="large" className="block" disabledDate={disabledDate} disabled={disabled} />
                    </Form.Item>
                </div>
                <div className="col-span-3">
                    <Form.Item name={[name, 'hour']} label={label && `${label} hour`} rules={helpers.requiredInput}>
                        <Select size="large" placeholder="HH" disabled={disabled}>
                            {[...Array(24)].map((_, i) => {
                                const hour = i > 9 ? i: `0${i}`;
                                return (
                                    <Select.Option key={hour} value={hour}>{hour}</Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </div>
                <div className="col-span-3">
                    <Form.Item name={[name, 'minute']} label={label && `${label} minute`} rules={helpers.requiredInput}>
                        <Select size="large" placeholder="MM" disabled={disabled}>
                            {[...Array(60)].map((_, i) => {
                                const minute = i > 9 ? i : `0${i}`;
                                return (
                                    <Select.Option key={minute} value={minute}>{minute}</Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </div>
            </div>
        </React.Fragment>
    );
}


export default DateTimePicker;