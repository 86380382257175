import { Avatar, Tag } from 'antd';
import { MaterialIcon, Status } from 'components';
import { helpers, types } from 'helpers';
import ReactCountryFlag from 'react-country-flag';


export const WhiteLabels = {
    heading: ['', 'Name', 'Credits', 'Status', 'Date'],
    data: (data: types.WhiteLabel[]) => data.map(row => {
        return {
            id: row.uuid,
            logo: <Avatar src={row.logo_link} size={'large'} />,
            name: row.name,
            credits: helpers.format.currency(row.credits),
            status: <Status status={row.status} label={row.status ? 'Enabled' : 'Disabled'} />,
            date: {
                width: 15,
                value: helpers.format.date(row.crdate),
            },
        };
    })
};

export const Developers = {
    heading: ['Name', 'Description', 'Credentials', 'Status', 'Date'],
    data: (data: types.Developer[]) => data.map(row => {
        return {
            id: row.uuid,
            name: {
                width: 20,
                value: row.name,
            },
            description: {
                width: 30,
                value: row.description || 'N/A',
            },
            credentials: (
                <>
                    <div
                        className="px-2 py-1 rounded-xl bg-gray-100 hover:bg-gray-200 transi truncate cursor-pointer flex items-center gap-1 text-xs"
                        onClick={() => helpers.copyToClipboard(`${row.credentials.key}.${row.credentials.secret}`)}
                    >
                        <MaterialIcon name="content_copy" className="text-xl" /> {row.credentials.key}.{row.credentials.secret}
                    </div>
                </>
            ),
            status: <Status status={row.status} label={row.status ? 'Enabled' : 'Disabled'} />,
            date: {
                width: 15,
                value: helpers.format.date(row.crdate),
            },
        };
    })
};

export const Senders = {
    heading: ['name', 'description', 'status', 'date'],
    data: (data: types.Sender[]) => data.map(row => {
        const status = ['Pending', 'Approved', 'Rejected', 'Deleted'];
        return {
            id: row.uuid,
            name: row.name,
            description: row.description || 'N/A',
            status: <Status status={row.status} label={status[row.status]} />,
            date: helpers.format.date(row.crdate),
        };
    })
};

export const Templates = {
    heading: ['Name', 'Message', 'Date'],
    data: (data: types.Template[]) => data.map(row => {
        return {
            id: row.uuid,
            name: {
                width: 14,
                value: row.name,
            },
            message: {
                value: row.message || 'N/A',
            },
            date: {
                width: 15,
                value: helpers.format.date(row.crdate),
            },
        };
    })
};

export const Contacts = {
    heading: ['Contact', 'Group(s)', 'Customs'],
    data: (data: types.Contact[], groups: types.ContactGroup[]) => data.map(row => {
        return {
            id: row.uuid,
            name: (
                <>
                    <ReactCountryFlag countryCode={row.country.code} /> <b>{row.number}</b> <br />
                    <span className="text-muted">{row.first_name} {row.last_name}</span>
                </>
            ),
            groups: groups.filter(g => row.groups.split(',').includes(g.uuid)).map(g => g.name).join(', '),
            customs: (
                <>
                    {(row.custom1 || row.custom2 || row.custom3 || row.custom4) ? (
                        <span className="tags">
                            {row.custom1 && (<Tag>{row.custom1}</Tag>)}
                            {row.custom2 && (<Tag>{row.custom2}</Tag>)}
                            {row.custom3 && (<Tag>{row.custom3}</Tag>)}
                            {row.custom4 && (<Tag>{row.custom4}</Tag>)}
                        </span>
                    ) : <span className="text-muted">N/A</span>}
                </>
            ),
        };
    })
};

export const Messages = {
    heading: ['Sender', 'Message', 'Credits', 'Status', 'Date'],
    data: (data: types.Message[]) => data.map(row => {
        const status = ['In Queue', 'Processed', 'Out of Credit', 'Deleted', 'Unregistered Sender'];
        return {
            id: row.uuid,
            sender: {
                width: 13,
                value: row.sender,
            },
            message: {
                width: 45,
                value: row.message,
            },
            credits: {
                width: 10,
                value: helpers.format.currency(row.credits),
            },
            status: {
                width: 15,
                value: <Status status={row.status} label={status[row.status]} />,
            },
            date: {
                width: 15,
                value: helpers.format.date(row.crdate),
            },
        };
    })
};

export const MessagesDelivery = {
    heading: ['Number', 'Credits', 'Status', 'Date'],
    data: (data: types.MessageDelivery[]) => data.map(row => {
        return {
            id: row.uuid,
            number: row.number,
            credits: {
                width: 20,
                value: helpers.format.currency(row.credits),
            },
            status: (
                <div className="flex items-center gap-1">
                    {row.status === 1 ? <MaterialIcon name="check_circle" className="text-xl primary fill" /> : <MaterialIcon name="cancel" className="text-xl danger fill" />}
                    {row.status_text}
                </div>
            ),
            date: {
                width: 15,
                value: helpers.format.date(row.crdate),
            },
        };
    })
};

export const Payments = {
    heading: ['Token', 'Description', 'Amount', 'Status', 'Date'],
    data: (data: types.Payment[]) => data.map(row => {
        const status = ['Pending', 'Succesful', 'Failed'];
        return {
            id: row.uuid,
            token: row.token,
            description: row.description,
            amount: helpers.format.currency(row.amount),
            status: <Status status={row.status} label={status[row.status]} />,
            date: {
                width: 15,
                value: helpers.format.date(row.crdate),
            },
        };
    })
};

export const Commissions = {
    heading: ['Commission', 'Referral', 'Amount', 'Date'],
    data: (data: types.Commission[]) => data.map(row => {
        return {
            id: row.uuid,
            commission: row.commission,
            referral: row.referral.name,
            amount: helpers.format.currency(row.amount),
            date: {
                width: 15,
                value: helpers.format.date(row.crdate),
            },
        };
    })
};