import React from 'react';
import _Store from '_Store';
import { Button, Drawer, Form, Input, notification } from 'antd';
import { axius, helpers, images } from 'helpers';
import Alert from './Alert';

interface Props {
    open: boolean;

    onCancel: () => void;
}
const TransferCommissions = (props: Props) => {
    const { open } = props;
    const { _store: { _auth: { user } },_setUser } = React.useContext(_Store);

    const [form] = Form.useForm();

    const [step, setStep] = React.useState(1);
    const [errMessage, setErrMessage] = React.useState('');
    const [submitting, setSubmitting] = React.useState(false);

    const source = 'commissions';
    const destination = 'credits';


    React.useEffect(() => {
        if (open) {
            setStep(3);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const submit = () => {
        const validates = {
            1: ['user'],
            2: [],
            3: ['amount'],
        }; console.log(validates[step])
        form.validateFields(validates[step]).then(v => {
            switch (step) {
                default:
                    setStep(step + 1);
                    break;
                case 1:
                    setSubmitting(true);
                    setErrMessage('');
                    axius.get(`users/${v.user}`).then(res => {
                        setSubmitting(false);
                        if (res.status === 200) {
                            setStep(step + 1);
                        } else {
                            setErrMessage(`No user was found with: ${v.user}`);
                        }
                    });
                    break;
                case 3:
                    setSubmitting(true);
                    v['user'] = user.uuid;
                    v['source'] = source;
                    v['destination'] = destination;
                    axius.put(`users/${user.uuid}/transfers`, v).then(res => {
                        setSubmitting(false);
                        if (res.status === 200) {
                            close();
                            _setUser(res.sender);
                            // props.signInSuccess(res.sender);
                            notification.success({ message: 'Tranfer succesful' });
                        } else {
                            setErrMessage(res.message);
                        }
                    });
                    break;

            }
        });
    }

    const close = () => {
        setStep(1);
        form.resetFields();
        props.onCancel();
    }

    return (
        <React.Fragment>
            <Drawer
                open={open}
                title={null}
                width={480}
                closable={true}
                onClose={close}
                maskClosable={false}
            >
                {errMessage && (
                    <div className="alert alert-danger" dangerouslySetInnerHTML={{ __html: errMessage }} />
                )}
                <div className="flex justify-between items-center mb-8">
                    <div className="millik text-xl font-semibold">Transfer {source}</div>
                    <div
                        className="bg-gray-100 rounded-full cursor-pointer hover:bg-gray-200 p-3 ml-2"
                        onClick={close}
                    >
                        <img src={images.Close} alt="Close" />
                    </div>
                </div>

                <Alert icon={false} color="info" className="mb-8">
                    <div className="py-2">Your current commissions <b>{helpers.format.currency(user.commissions)}</b></div>
                </Alert>

                <Form form={form} layout="vertical" onFinish={submit}>
                    {/* <div className={step !== 1 ? 'hidden' : ''}>
                        <Form.Item label="Recipient email / username" name="user" rules={helpers.requiredInput}>
                            <Input autoComplete="off" size="large" autoFocus placeholder="" disabled={submitting} />
                        </Form.Item>
                    </div> */}

                    {/* <div className={step !== 2 ? 'hidden' : ''}>
                        <div className="summary">
                            <div className="div">
                                <div className="pull-left">Name:</div>
                                <div className="pull-right">{user.name}</div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="div">
                                <div className="pull-left">Email address:</div>
                                <div className="pull-right">{user.email}</div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="div">
                                <div className="pull-left">Phone no.:</div>
                                <div className="pull-right">{user.phone}</div>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div> */}

                    <div className={step !== 3 ? 'hidden' : ''}>
                        <Form.Item label="Amount to transfer" name="amount" rules={helpers.requiredInput}>
                            <Input autoComplete="off" size="large" placeholder="" addonBefore={(user.country || {}).currency} disabled={submitting} />
                        </Form.Item>
                    </div>

                    <div className="grid grid-cols-12 gap-4">
                        <div className="col-span-12 mt-12">
                            <Button type="primary" size="large" htmlType="submit" block loading={submitting}>
                                Submit
                            </Button>
                        </div>
                    </div>

                    <div className="mt-4 hidden">
                        <div className="float-left">
                            {step > 1 && (
                                <Button type="default" loading={submitting} onClick={() => setStep(step - 1)}>
                                    back
                                </Button>
                            )}
                        </div>
                        <div className="float-right">
                            <Button type="primary" loading={submitting} onClick={submit}>
                                {step === 3 ? 'Transfer' : 'Next'}
                            </Button>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </Form>
            </Drawer>
        </React.Fragment>
    );
}

export default TransferCommissions;