import React from 'react';

interface Props {
    name: string;
    className?: string;
}
const MaterialIcon = (props: Props) => {

    return (
        <React.Fragment>
            <div id="MaterialIcon" className={`material-symbols-outlined ${props.className}`}>
                {props.name}
            </div>
        </React.Fragment>
    );
}

export default MaterialIcon;