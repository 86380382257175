import React from 'react';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom';
import { Loading, Leyout } from './components';
import { routes } from 'helpers';


const App = () => {

    const Layout = () => {

        return (
            <React.Fragment>
                <Leyout>
                    <Outlet />
                </Leyout>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Loading full={true} />
            <BrowserRouter>
                <React.Suspense fallback={<Loading full={true} spinning={true} />}>
                    <Routes>
                        <Route element={<Layout />}>
                            {routes.map(route => (
                                <Route
                                    key={route.path}
                                    path={route.path}
                                    element={<route.element />}
                                />
                            ))}
                        </Route>
                    </Routes>
                </React.Suspense>
            </BrowserRouter>
        </React.Fragment>
    );
};

export default App;