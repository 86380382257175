import React from 'react';

// ::: app pages
const TopUp = React.lazy(() => import('pages/TopUp'));
const Compose = React.lazy(() => import('pages/Compose'));
const Senders = React.lazy(() => import('pages/Senders'));
const Reports = React.lazy(() => import('pages/Reports'));
const Contacts = React.lazy(() => import('pages/Contacts'));
const Dashboard = React.lazy(() => import('pages/Dashboard'));
const Templates = React.lazy(() => import('pages/Templates'));
const Developers = React.lazy(() => import('pages/Developers'));
const WhiteLabels = React.lazy(() => import('pages/WhiteLabels'));


// ::: app routes
const routes = [
    { path: `/`, element: Dashboard, auth: true, },
    { path: `/topup`, element: TopUp, auth: true, },
    { path: `/senders`, element: Senders, auth: true, },
    { path: `/reports/*`, element: Reports, auth: true, },
    { path: `/compose`, element: Compose, auth: true, },
    { path: `/contacts`, element: Contacts, auth: true, },
    { path: `/templates`, element: Templates, auth: true, },
    { path: `/developers`, element: Developers, auth: true, },
    { path: `/whitelabels`, element: WhiteLabels, auth: true, },
    { path: `/buy-credits`, element: TopUp, auth: true, },

    { path: `*`, element: Dashboard, auth: true, },
];

export default routes;