import React from 'react';
import * as antd from 'antd';

interface Props {
    total: number;
    perPage: number;
    current?: number;
    navigate: (cursor: number) => void;
}
const Pagination = ({ total, perPage, navigate }: Props) => {

    const [step, setStep] = React.useState(0);

    const current = step === 0 ? step + 1 : (step / perPage) + 1;

    return (
        <React.Fragment>
            <div id="Pagination">
                <div className="flex justify-between items-center">
                    <div>
                        {total > perPage && (
                            <antd.Pagination
                                total={total}
                                pageSize={perPage}
                                current={current}
                                onChange={e => {
                                    setStep((e - 1) * perPage);
                                    navigate((e - 1) * perPage);
                                    window.scrollTo({ top: 0, behavior: 'smooth' });
                                }}
                                showSizeChanger={false}
                            />
                        )}
                    </div>
                    <div className="text-sm text-gray-500">
                        Page {current} of {Math.round(total / perPage) || 1}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}


export default Pagination;