import React from 'react';
import * as antd from 'antd';
import { Input, Select } from 'antd';
import { helpers, images, types } from 'helpers';
import dayjs from 'dayjs';
import MaterialIcon from './MaterialIcon';

interface Props {
    filters: types.Filter[];
    setFilters: (a: any) => void;
}
const Filter = ({ filters, setFilters }: Props) => {

    const [open, setOpen] = React.useState(false);
    const [date, setDate] = React.useState('1M');
    const [filtars, setFiltars] = React.useState<types.Filter[]>([]);

    const mf = 'YYYY-MM';
    const df = 'YYYY-MM-DD';
    const dates = {
        '1D': [dayjs().format(df), dayjs().format(df), 'days'],
        '1W': [dayjs().subtract(7, 'day').format(df), dayjs().format(df), 'days'],
        '1M': [dayjs().startOf('month').format(df), dayjs().endOf('month').format(df), 'days'],
        '3M': [dayjs().subtract(2, 'month').startOf('month').format(mf), dayjs().endOf('month').format(mf), 'months'],
        '6M': [dayjs().subtract(5, 'month').startOf('month').format(mf), dayjs().format(mf), 'months'],
        '1Y': [dayjs().startOf('year').format(mf), dayjs().format(mf), 'months'],
    };

    const ranges = [
        { value: '1D', label: 'Today' },
        { value: '1W', label: '7 days' },
        { value: '1M', label: 'This month' },
        { value: '3M', label: '3 months' },
        // { value: '1Y', label: '1 year' },
    ];

    React.useEffect(() => {
        const filtarsCopy = helpers.copyObject(filtars) as types.Filter[];
        const resetValues = filters.map(filter => {
            const filtar = filtarsCopy.find(f => f.key === filter.key) as types.Filter;
            return {
                ...filter,
                value: filtar?.value || filter.value || '',
            };
        });
        setFiltars(resetValues);
        // eslint-disable-next-line
    }, [filters]);

    React.useEffect(() => {
        if (date) {
            const index = filters.indexOf(filters.find(filter => filter.type === 'date_range') as any);
            if (index > -1) {
                inputChange(index, `${dates[date as keyof typeof dates][0]},${dates[date as keyof typeof dates][1]}`);
            }
        }
        // eslint-disable-next-line
    }, [date]);

    const reset = () => {
        const filtersCopy = helpers.copyObject(filters) as types.Filter[];
        const resetValues = filtersCopy.map(filter => {
            return {
                ...filter,
                value: '',
            };
        });
        setFiltars(resetValues);
        setFilters(resetValues);
        close();
    }

    const inputChange = (i: number, value: string) => {
        const filtarsCopy = helpers.copyObject(filtars) as types.Filter[];
        if (filtarsCopy[i]) {
            filtarsCopy[i]['value'] = value;
            setFiltars(filtarsCopy);
        }
    }

    const submit = () => {
        setFilters(filtars);
        close();
    }

    const close = () => {
        setOpen(false);
    }

    // const dateValue = (filtars.find(filter => filter.type === 'date_range') || {}).value || `${dates['1M'][0]},${dates['1M'][1]}`;
    const dateValue = (filtars.find(filter => filter.type === 'date_range') || {}).value || '';

    return (
        <React.Fragment>
            <div id="Filter">
                <div className="flex items-center gap-4 text-sm">
                    <div
                        className="transi cursor-pointer hover:border-[#0089ff] hover:text-[#0089ff] flex items-center"
                        onClick={() => setOpen(true)}
                    >
                        <MaterialIcon name="filter_alt" className="text-2xl" /> &nbsp; Filters &nbsp;
                    </div>
                    {filters.filter(f => f.value).map(filter => {
                        const labelValue = filter.options && typeof filter.options[0] !== 'string' ? (filter.options.find((fo: any) => fo.value === filter.value) as any)?.label : '';
                        return (
                            <div key={filter.key} className="text-gray-500">
                                {helpers.ucFirst(filter.label || filter.key)}: {labelValue || (filter.value as any)}
                            </div>
                        )
                    })}
                </div>
            </div>

            <antd.Drawer
                open={open}
                title={null}
                width={420}
                closable={true}
                onClose={close}
                maskClosable={false}
            >
                <div className="flex justify-between items-center mb-4">
                    <div className="text-xl font-medium">Filters</div>
                    <div
                        className="bg-gray-100 rounded-full cursor-pointer hover:bg-gray-200 p-3 ml-2"
                        onClick={close}
                    >
                        <img src={images.Close} alt="Close" />
                    </div>
                </div>

                <div className="flex flex-col justify-between" style={{ height: '80vh' }}>
                    <div>
                        {filters.map((filter, index) => (
                            <div key={filter.key}>
                                {['text', 'number'].includes(filter.type) && (
                                    <div className="mb-6">
                                        <div className="font-medium mb-2 text-gray-500">{helpers.ucFirst(filter.label || filter.key)}</div>
                                        <Input
                                            size="large"
                                            type={filter.type}
                                            value={filtars[index]?.value || ''}
                                            placeholder={filter.placeholder}
                                            onChange={e => inputChange(index, e.target.value)}
                                            onPressEnter={submit}
                                        />
                                    </div>
                                )}

                                {filter.type === 'date_range' && (
                                    <div className="mb-6">
                                        <div className="font-medium mb-2 text-gray-500 hidden">Select date</div>
                                        <div className="grid- grid-cols-4 gap-2 hidden">
                                            {ranges.map(range => (
                                                <antd.Button
                                                    key={range.value}
                                                    type={date === range.value ? 'primary' : 'default'}
                                                    size="middle"
                                                    className="text-sm"
                                                    onClick={() => setDate(range.value)}
                                                >
                                                    {range.label}
                                                </antd.Button>
                                            ))}
                                        </div>
                                        <div className="font-medium mb-2 mt-6 text-gray-500">Date range</div>
                                        <antd.DatePicker.RangePicker
                                            size="large"
                                            format="MMM DD, YYYY"
                                            value={dateValue ? [dayjs(dateValue.split(',')[0]), dayjs(dateValue.split(',')[1])] : null}
                                            className="w-full"
                                            onChange={e => {
                                                if (e) {
                                                    setDate('');
                                                    inputChange(index, `${e[0]?.format('YYYY-MM-DD')},${e[1]?.format('YYYY-MM-DD')}`);
                                                }
                                            }}
                                        />
                                    </div>
                                )}

                                {filter.type === 'select' && filter.options && filter.options.length > 0 && (
                                    <div className="mb-6">
                                        <div className="font-medium mb-2 text-gray-500">{helpers.ucFirst(filter.label || filter.key)}</div>
                                        <Select
                                            className="w-full"
                                            value={filtars[index]?.value || ''}
                                            onChange={e => inputChange(index, e)}
                                            showSearch={filter.options.length > 5}
                                            optionFilterProp="children"
                                        >
                                            <Select.Option value={""}>Show all</Select.Option>
                                            {filter.options.map(option => {
                                                option = typeof option === 'string' ? { label: option, value: option } : option;
                                                return (
                                                    <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>

                    <div className="py-8 grid grid-cols-12 gap-6">
                        <div className="col-span-4">
                            <antd.Button color="primary" size="large" block onClick={reset}>
                                Clear all
                            </antd.Button>
                        </div>
                        <div className="col-span-8">
                            <antd.Button color="primary" size="large" block type="primary" onClick={submit}>
                                Apply
                            </antd.Button>
                        </div>
                    </div>
                </div>
            </antd.Drawer>
        </React.Fragment>
    );
}


export default Filter;