import React from 'react';
import _Store from '_Store';
import { axius, config, helpers, images } from 'helpers';
import MaterialIcon from './MaterialIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import { Drawer } from 'antd';
import Loading from './Loading';
import moment from 'moment';
import queryString from 'query-string';

const Header = React.lazy(() => import('./Header'));

const Leyout = ({ children }: { children: any }) => {

    const location = useLocation();
    const navigate = useNavigate();

    const { _store: { _auth: { sso, user, token }, _data: { meCache } }, _setUser, _setData, _setSso, _setAuth } = React.useContext(_Store);

    const qs = queryString.parse(window.location.search);
    const current = location.pathname.split('/')[1];
    const isLoggedIn = !!(user.id && token);
    const isCompliant = sso.compliance?.document?.status && sso.compliance?.email && sso.compliance?.phone_number;

    const [side, setSide] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        if (qs.sso) {
            setLoading(true);
            helpers.setStorage('sso', qs.sso.toString());
            verifySsoToken();
        } else {
            verifySsoToken();
            setInterval(() => {
                verifySsoToken();
            }, 60000);
        }
        // eslint-disable-next-line 
    }, [window.location.search]);

    React.useEffect(() => {
        token && getMyDetails();
        token && getServerData();
        // eslint-disable-next-line 
    }, [meCache]);

    const verifySsoToken = () => {
        axius.get(`sso/auth`, { cache: helpers.randCode(12) }).then(res => {
            switch (res.status) {
                case 401:
                    axius.signOutSuccess();
                    break;
                case 200:
                    _setSso(res.sso);
                    _setUser(res.user);
                    if (qs.sso) {
                        _setAuth({ user: res.user, token: res.token });
                        navigate('/', { replace: true });
                        setTimeout(() => {
                            helpers.redirect('/');
                        }, 100);
                    }
                    break;
            }
        });
    }

    const getMyDetails = () => {
        axius.get(`users/${user.uuid}`, { cache: meCache }).then(res => {
            res.status === 200 && _setUser(res.data);
        });
    }

    const getServerData = () => {
        Promise.all([
            axius.get(`settings`),
            axius.get(`settings/countries`),
        ]).then(res => {
            res[0].status === 200 && _setData({ key: 'settings', value: res[0].data });
            res[1].status === 200 && _setData({ key: 'countries', value: res[1].data });
            // ::: check version and clear cache
            const oldVersion = helpers.versionBreak(config.version);
            const newVersion = helpers.versionBreak(res[0].data['global']['app_version']);
            if (newVersion > oldVersion) {
                caches.keys().then(keys => {
                    Promise.all(keys.map(key => caches.delete(key))).then(() => {
                        window.history.forward();
                        window.location.reload();
                    });
                });
            }
        });
    }

    const navigation = [
        { icon: 'home_app_logo', label: 'Overview', path: '/' },
        { icon: 'send', label: 'Compose Message', path: '/compose' },
        { icon: 'contacts', label: 'Contacts', path: '/contacts' },
        { icon: 'sticky_note', label: 'Templates', path: '/templates' },
        { icon: 'brand_family', label: 'Senders IDs', path: '/senders' },
        { icon: 'monitoring', label: 'Reports', path: '/reports' },
        { icon: 'payments', label: 'Top Up', path: '/topup' },
        { icon: 'code', label: 'Developers', path: '/developers' },
        { icon: 'toolbar', label: 'White Labels', path: '/whitelabels' },
    ];

    if (loading) {
        return <Loading full={true} spinning={true} primary="Authenticating" />;
    }

    if (!qs.sso) {
        if (!isLoggedIn) {
            axius.signOutSuccess();
            return false;
        } else if (isLoggedIn && !isCompliant) {
            helpers.redirect(config.sso_app + 'verification');
            return false;
        }
    }

    return (
        <React.Fragment>
            {isLoggedIn && isCompliant && (
                <div className="py-16s h-[100vh] flex">
                    {/* Navigation */}
                    <section id="Sidebar_Desktop" className="w-[250px] bg-[#F9F9F9] px-4 flex-row justify-between hidden xl:block">
                        <div className="h-5/6s">
                            <div className="py-16 flex items-center justify-center">
                                <img src={images.LogoIcon} alt="FayaSMS Logo" className="h-[56px]" />
                            </div>
                            {navigation.map(item => (
                                <div
                                    key={item.path}
                                    className={`
                                    flex items-center gap-2 transi pop rounded-lg py-2 mb-2 pl-6 cursor-pointer
                                    hover:text-black hover:bg-[#EDEDED] 
                                    ${current === item.path.replace('/', '') ? ' text-black bg-[#EDEDED] font-medium' : 'text-[#525866]'}
                                `}
                                    onClick={() => navigate(item.path)}
                                >
                                    <MaterialIcon name={item.icon} className={`text-2xl ${current === item.path.replace('/', '') ? 'primary' : ''}`} />
                                    {item.label}
                                </div>
                            ))}
                        </div>
                    </section>
                    <section id="Sidebar_Mobile" className="">
                        <Drawer
                            open={side}
                            title={null}
                            width={240}
                            closable={true}
                            placement="left"
                            onClose={() => setSide(ss => (!ss))}
                            rootClassName="Sidebar_Mobile"
                        >
                            <div className="flex justify-between ml-2s mt-4 mb-12">
                                <img src={images.Logo} alt="FayaSMS Logo" className=" h-[32px]" />
                                <div
                                    className="bg-gray-100 rounded-full cursor-pointer hover:bg-gray-200 p-3 ml-2"
                                    onClick={() => setSide(ss => (!ss))}
                                >
                                    <img src={images.Close} alt="Close" />
                                </div>
                            </div>

                            {navigation.map(item => (
                                <div
                                    key={item.path}
                                    className={`
                                    flex items-center gap-2 transi pop rounded-lg py-1 mb-2 pl-6 cursor-pointer
                                    hover:text-black hover:bg-[#EDEDED] 
                                    ${current === item.path.replace('/', '') ? ' text-black bg-[#EDEDED] font-medium' : 'text-[#525866]'}
                                `}
                                    onClick={() => { navigate(item.path); setSide(ss => (!ss)); }}
                                >
                                    <MaterialIcon name={item.icon} className={`text-2xl ${current === item.path.replace('/', '') ? 'primary' : ''}`} />
                                    {item.label}
                                </div>
                            ))}
                        </Drawer>
                    </section>

                    {/* Content */}
                    <section id="Content" className="w-full xl:w-[calc(100vw-250px)]">
                        <div className="hiddens"><Header setSide={() => setSide(ss => (!ss))} /></div>
                        <div className="flex justify-center pt-4s xl:pt-8s">
                            <React.Suspense fallback={<Loading spinning={true} />}>
                                <div className="w-11/12 xl:w-10/12 h-[calc(100vh-102px)] xl:h-[calc(100vh-122px)] overflow-scroll no-scrollbar">
                                    <div className="mt-8" />
                                    {children}
                                </div>
                            </React.Suspense>
                        </div>
                        <div className="text-center bg-gray-100s py-4 text-gray-500 text-xs">
                            Copyright © 2018-{moment().format('YYYY')} Anchora Technologies Ltd. v{config.version}
                        </div>
                    </section>
                </div>
            )}
        </React.Fragment>
    );
}

export default Leyout;